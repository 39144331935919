$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.Popover {
  .Dropdown__Menu {
    transform-origin: 0 0;
    transition: opacity 240ms ease-in-out, transform 240ms ease;
    opacity: 0;
    transform: scale(0.95);
    display: block !important;
    pointer-events: none;
    padding-top: $spacing * 0.65;
  }

  &.Dropdown--right {
    .Dropdown__Menu {
      transform-origin: 100% 0;
    }
  }

  .Dropdown__Content {
    position: relative;
    padding: $spacing !important;
    border-radius: 7px;
    max-height: none !important;
    min-width: 300px;

    .Delete {
      position: absolute;
      top: $spacing * 0.5;
      right: $spacing * 0.5;
    }
  }

  &.Dropdown--active {
    .Dropdown__Menu {
      opacity: 1;
      transform: scale(1);
      pointer-events: auto;
    }
  }
}
