$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.SwGameTile {
  display: block;
  width: 112px;

  p {
    font-size: 80%;
    margin-top: $marginSm1;
    line-height: 1.1;
    
    &:first-of-type {
      margin-top: $marginSm2;
    }
  }

  &--skeleton {
    pointer-events: none;
    min-height: 133px;
    
    p {
      border-radius: 3px;

      &:first-of-type {
        width: 90%;
      }

      &:last-of-type {
        width: 60%;
      }
    }
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
