$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.SwPaginator {
  a {
    margin: auto 0;
    font-weight: bold;
    text-decoration: underline;
    font-size: 90%;
    cursor: pointer;
  }

  .Button,
  .Buttons {
    margin-bottom: 0 !important;
  }
}