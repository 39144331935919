$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.LoginButton {
  display: flex;
  align-items: center;
  border-radius: $radius;

  > .Button {
    box-shadow: none !important;
    display: inline-flex;
    align-items: center;
    
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 0;
  
      &::after {
        content: '';
        display: inline-block;
        width: 2px;
        height: 1.5em;
        border-right: solid 2px;
        opacity: 0.5;
        margin-left: 0.5em;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 0.5em;
    }
  }
}
