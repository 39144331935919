$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.UserAvatar {
  font-size: 0;
  word-break: 0;
  width: 140px;
  height: 140px;

  > span {
    border-radius: $controlRadius;
    box-shadow: 0 2px 6px rgba(#000, 0.2);
  }

  &--small {
    width: 40px;
    height: 40px;

    > span {
      border-radius: $controlRadius * 0.65;
    }
  }

  &--medium {
    width: 70px;
    height: 70px;

    > span {
      border-radius: $controlRadius * 0.65;
    }
  }

  &--mediumLarge {
    width: 90px;
    height: 90px;

    > span {
      border-radius: $controlRadius * 0.65;
    }
  }

  &--noBoxShadow {
    > span {
      box-shadow: none;
    }
  }

  &--selected {
    > span {
      box-shadow: 0 0 0 3px;
    }
    img {
      opacity: 0.65;
    }
  }
}
